<template>
  <v-container class="mt-4 px-5" fluid>
    <v-row>
    	<v-col cols="12">
    		<v-card class="shadowCard">
				  <v-card-title primary-title>
				    <span class="text-subtitle-1">Reporte de efectividad</span>
				    <v-spacer></v-spacer>

		        <v-btn
		          color="primary"
		          dark
		          class="text-capitalize"
		          @click="initialize()"
		          small
		          tile
		        >
		          <v-icon small>mdi-refresh</v-icon>
		        </v-btn>
		        
				  </v-card-title>

				  <v-card-text>
				  	<v-row justify="end">

				  		<v-col cols="12" md="4">
				  			<v-text-field
				  			  name="name"
				  			  label="Buscar"
				  			  id="id"
				  			  filled
				  			  dense
				  			  single-line
				  			  hide-details
				  			  v-model="fechaini"
				  			  type="date"
				  			>
				  			</v-text-field>
				  		</v-col>

				  		<v-col cols="12" md="8">
				  			<v-text-field
				  			  name="name"
				  			  label="Buscar"
				  			  id="id"
				  			  filled
				  			  dense
				  			  single-line
				  			  hide-details
				  			  append-icon="mdi-magnify"
				  			  v-model="search"
				  			>
				  			</v-text-field>
				  		</v-col>
				  	</v-row>

				    <v-row>
				    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="vendedoras"
							    class="elevation-0"
							    :search="search"
							    :mobile-breakpoint="100"
			            dense
							  >

		              <template v-slot:item.utlimocontacto="{ item }">
		                <v-icon color="green" small right v-if="item.minutos < 20">mdi-circle</v-icon>
		                <v-icon color="yellow" small right v-if="item.minutos >= 20 && item.minutos <= 59 ">mdi-circle</v-icon>
		                <v-icon color="red" small right v-if="item.minutos >= 60">mdi-circle</v-icon>
		                <span @click="verMensajes( item )">{{ item.utlimocontacto }}</span>
		              </template>

		              <template v-slot:item.espera="{ item }">
		                <v-btn 
		                  color="orange" 
		                  x-small
		                  @click="tabla = item.espera_C, dialogContactos = true"
		                  class="mr-2"
		                  dark
		                >
		                  {{item.espera}}
		                </v-btn>
		              </template>

		              <template v-slot:item.verdes="{ item }">
		                <v-btn 
		                  color="green" 
		                  x-small
		                  @click="tabla = item.verdes_C, dialogContactos = true"
		                  class="mr-2"
		                  dark
		                >
		                  {{item.verdes}}
		                </v-btn>
		              </template>

		              <template v-slot:item.amarillos_mios="{ item }">
		                <v-btn 
		                  color="yellow" 
		                  x-small
		                  @click="tabla = item.amarillos_mios_C, dialogContactos = true"
		                  class="mr-2"
		                >
		                  {{item.amarillos_mios}}
		                </v-btn>
		              </template>

		              <template v-slot:item.rojos_mios="{ item }">
		                <v-btn 
		                  color="red" 
		                  x-small
		                  @click="tabla = item.rojos_mios_C, dialogContactos = true"
		                  class="mr-2"
		                  dark
		                >
		                  {{item.rojos_mios}}
		                </v-btn>
		              </template>


		              <template v-slot:item.verdes_mios="{ item }">
		                <v-btn 
		                  color="green" 
		                  x-small
		                  @click="tabla = item.verdes_mios_C, dialogContactos = true"
		                  class="mr-2"
		                  dark
		                >
		                  {{item.verdes_mios}}
		                </v-btn>
		              </template>

		              <template v-slot:item.amarillos="{ item }">
		                <v-btn 
		                  color="yellow" 
		                  x-small
		                  @click="tabla = item.amarillos_C, dialogContactos = true"
		                  class="mr-2"
		                >
		                  {{item.amarillos}}
		                </v-btn>
		              </template>

		              <template v-slot:item.rojos="{ item }">
		                <v-btn 
		                  color="red" 
		                  x-small
		                  @click="tabla = item.rojos_C, dialogContactos = true"
		                  class="mr-2"
		                  dark
		                >
		                  {{item.rojos}}
		                </v-btn>
		              </template>


							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
				    	</v-col>
				    </v-row>
				  </v-card-text>
				</v-card>
    	</v-col>
    </v-row>


    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar2"/>

  </v-container>
</template>


<script>
  import axios from 'axios';
  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'
  import mixinLeads            from '@/mixins/mixinLeads'
  import basicosTablas         from '@/mixins/basicosTablas'
  import SeguimientoVendedora  from '@/components/leads/SeguimientoVendedora'

  export default {
    components:{
      Alerta,
      carga,
      SeguimientoVendedora,
    },

    mixins: [ validarErrores, funcionesExcel, mixinLeads, basicosTablas ],
    
    //Data
    data: () => ({

      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      //datos que se desplegaran en las 2 tablas
      prospectos: [],
      usuarios: [],
      usuario: null,
      dialogUsuario: false,
      dialog: false,

      headers: [
        { text: "Folio"    , value: "folio" },
        { text: "Plantel"  , value: "plantel" },
        { text: "Telefono" , value: "telefono" },
        { text: "Nombre"   , value: "nombre_completo" },
        { text: "Vendedora", value: "name" },
        { text: "Estatus"  , value: "estatus" },
        { text: "Completo" , value: "completo" },
        { text: "Match"    , value: "match" },
        { text: "Fecha"    , value: "fecha_creacion" },
      ],

      fechaini: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      fechafin: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),


      search: "",

      escuela: 1,

      vendedoras: [],

      headers2:[
        { text: "Vendedora"         , value: "vendedora" },
        { text: "% carga laboral"   , value: "carga" },
        { text: "Contactos"         , value: "contactosall" },
        { text: "% convencimiento"  , value: "convencimiento" },
        { text: "Contactos hoy"     , value: "contactos" },
        { text: "ranking"           , value: "ranking" },
        { text: "puedeRecibir"      , value: "puedeRecibir" },
      ],
      search2:'',

      contactosVsFecha:[],
      contactosInscritos:[],

      cursos_escuela:[],
      medios_contacto:[],

      searchTelefono:'',

      editedItem: {
        nombre_completo:'',
        idleds:'',
        escuela: 1,
        iddetalle_fuentes:0,
        idfuentes: 0,
        fuente: '',
        estatus: '',
        foraneo: 0,
        idcursos_escuela: 0,
        telefono:'',
        sucursal:0,
        nota_inicial:'El prospecto desea información',
        idmedio_contacto:0,
        idcampanias:0,
        id_sucursal_interes:0,
        curso_interes: 0,
        horario_interes:'',
        respondio: 0,
      },

      sucursales:[],
      sucursal:null,
      vendedoras_plantel:[],
      vendedora:null,
      campanias:[],
      campania:null,
      fuentes:[],

      dialogTwilio: {
        estatus: false
      },

      dialogVendedora: {
        estatus: false
      },

      dialogDiferencias: {
        estatus: false
      },

      headers: [
	      { text: "Vendedora"        , value: "vendedora"         },
	      { text: "Contactados"      , value: "contactados"       },
	      { text: "Mensajes S"       , value: "mensajes_enviados" },
	      { text: "Hora inicio"      , value: "primer_contacto"   },
	      { text: "Hora final"       , value: "ultimo_contacto"   },
	      { text: "T. Total"         , value: "tiempo_total"      },
	      { text: "> Inactividad"    , value: "mayor_inactividad" },
	      { text: "T. Inactivo"      , value: "tiempo_inactivo"   },
	      { text: "T. Activo"        , value: "tiempo_activo"     },
	    ],

      headersContactos:[
        { text: "Folio"         , value: "folio"                  },
        { text: "Telefono"      , value: "usuario"                },
        { text: "Nombre"        , value: "nombre_completo"        },
        { text: "Zona"          , value: "zona"        },
        { text: "T. Espera"     , value: "tiempo_espera"          },
      ],
      tabla:[],
      dialogContactos: false,
      cargar2: false,

      mensaje: null,
      dialogVerMensaje: false,
      url :'https://testfast.club/imagenes-whatsapp/',
      url2: 'https://escuelakpi.club/imagenes-whatsapp/',


    }),

    //Computed
    computed: {

      ...mapGetters('login',['getdatosUsuario','getEscuela']),

      formTitle () {

        return this.editedIndex === -1 ? 'Agregar un LEAD' : 'Editar LEAD'

      },

    },

    watch: {
      dialogUsuario(val) {
        val || this.close();
      },
    },

    async created() {
      await this.initialize();
    },

    //Methods
    methods: {

      //Obtener prospectos y usuarios
      initialize() {
      	this.vendedoras = []
        this.cargar2 = true;
        this.vendedoras = []

        const payload = {
	        escuela : this.getEscuela,
	        fechaini: this.fechaini
	      }

        return axios.post('reporte.efectividad', payload ).then(response => {
    		
    			this.vendedoras = response.data.vendedoras.filter( el => el.id_vendedora == this.getdatosUsuario.iderp ) 

          this.cargar2 = false;

        }).catch(error => {
          this.validarError(error.response.data.message);
        }).finally(() => {
          this.cargar = false;
        });
      },
    },
  };
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-4 px-5",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"shadowCard"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v("Reporte de efectividad")]),_c('v-spacer'),_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"primary","dark":"","small":"","tile":""},on:{"click":function($event){return _vm.initialize()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-refresh")])],1)],1),_c('v-card-text',[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"name":"name","label":"Buscar","id":"id","filled":"","dense":"","single-line":"","hide-details":"","type":"date"},model:{value:(_vm.fechaini),callback:function ($$v) {_vm.fechaini=$$v},expression:"fechaini"}})],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-text-field',{attrs:{"name":"name","label":"Buscar","id":"id","filled":"","dense":"","single-line":"","hide-details":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.vendedoras,"search":_vm.search,"mobile-breakpoint":100,"dense":""},scopedSlots:_vm._u([{key:"item.utlimocontacto",fn:function(ref){
var item = ref.item;
return [(item.minutos < 20)?_c('v-icon',{attrs:{"color":"green","small":"","right":""}},[_vm._v("mdi-circle")]):_vm._e(),(item.minutos >= 20 && item.minutos <= 59 )?_c('v-icon',{attrs:{"color":"yellow","small":"","right":""}},[_vm._v("mdi-circle")]):_vm._e(),(item.minutos >= 60)?_c('v-icon',{attrs:{"color":"red","small":"","right":""}},[_vm._v("mdi-circle")]):_vm._e(),_c('span',{on:{"click":function($event){return _vm.verMensajes( item )}}},[_vm._v(_vm._s(item.utlimocontacto))])]}},{key:"item.espera",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"orange","x-small":"","dark":""},on:{"click":function($event){_vm.tabla = item.espera_C, _vm.dialogContactos = true}}},[_vm._v(" "+_vm._s(item.espera)+" ")])]}},{key:"item.verdes",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"green","x-small":"","dark":""},on:{"click":function($event){_vm.tabla = item.verdes_C, _vm.dialogContactos = true}}},[_vm._v(" "+_vm._s(item.verdes)+" ")])]}},{key:"item.amarillos_mios",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"yellow","x-small":""},on:{"click":function($event){_vm.tabla = item.amarillos_mios_C, _vm.dialogContactos = true}}},[_vm._v(" "+_vm._s(item.amarillos_mios)+" ")])]}},{key:"item.rojos_mios",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"red","x-small":"","dark":""},on:{"click":function($event){_vm.tabla = item.rojos_mios_C, _vm.dialogContactos = true}}},[_vm._v(" "+_vm._s(item.rojos_mios)+" ")])]}},{key:"item.verdes_mios",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"green","x-small":"","dark":""},on:{"click":function($event){_vm.tabla = item.verdes_mios_C, _vm.dialogContactos = true}}},[_vm._v(" "+_vm._s(item.verdes_mios)+" ")])]}},{key:"item.amarillos",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"yellow","x-small":""},on:{"click":function($event){_vm.tabla = item.amarillos_C, _vm.dialogContactos = true}}},[_vm._v(" "+_vm._s(item.amarillos)+" ")])]}},{key:"item.rojos",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"red","x-small":"","dark":""},on:{"click":function($event){_vm.tabla = item.rojos_C, _vm.dialogContactos = true}}},[_vm._v(" "+_vm._s(item.rojos)+" ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":_vm.initialize}},[_vm._v(" Actualizar ")])]},proxy:true}])})],1)],1)],1)],1)],1)],1),(_vm.respuestaAlerta)?_c('Alerta',{attrs:{"parametros":_vm.parametros}}):_vm._e(),(_vm.cargar2)?_c('carga'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }